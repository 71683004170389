<template>
  <v-col cols="12" class="mt-6">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters justify="space-between">
          <h3 class="font-weight-bold">{{$t('view.wallet-web3.title')}}</h3>
        </v-row>
      </v-col>

      <v-col cols="12" class="text-center" v-if="web3 && walletNetwork && MetaMaskAddress">
        <p>{{ $t('view.wallet-web3.isConnected.address', { walletAddress: MetaMaskAddress, walletNetwork }) }}</p>
        <p>{{ $t('view.wallet-web3.isConnected.balance') }} {{ walletBalance }}</p>
      </v-col>

      <v-col cols="12" class="text-center" v-else>
        <v-btn
            color="primary"
            style="text-transform: none !important;"
            @click="initWeb3"
        >
          <img src="@/assets/pictures/MetaMask.svg" width="20" class="mx-1" />
          {{ $t('view.wallet-web3.buttons.connect') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import web3Mixin from "@/mixins/web3";
import formatterMixin from "@/mixins/formatter";

export default {
  name: "WalletWeb3",
  mixins: [web3Mixin, formatterMixin],

  mounted() {

  }
}
</script>

<style scoped>

</style>
